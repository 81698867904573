import React, { useEffect, useRef } from 'react';
import { motion, useTransform, MotionValue, AnimationControls } from 'framer-motion';
import Img, { FluidObject } from 'gatsby-image';
import { Link, navigate } from 'gatsby';
import { IoIosArrowRoundForward } from 'react-icons/io';

import useThrottle from 'src/utils/useThrottle';
import styled, { mq } from 'src/components/styled';
import Typography from 'src/components/Typography';
import { IconButton } from 'src/components';

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 10;
  margin-top: -26px;
`;

const Mockup = styled(motion.div)`
  ${mq({
    height: ['320px', '440px', '572px'],
  })}
`;

const Footer = styled(motion.div)`
  text-align: center;
`;
const ProjectSubtitle = styled(Typography)`
  color: #757575;
  margin-bottom: 6px;
  font-weight: 300;
  letter-spacing: 1px;
`;
const ProjectTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 800;
  color: #333;
  margin-top: 36px;
  margin-bottom: 0;
`;

const ProjectLinkContainer = styled.span`
  @media (hover: hover) {
    display: none;
  }
`;

const ProjectLink = styled(Link)`
  pointer-events: auto;
  color: ${(p) => p.color};
  &:hover {
    opacity: 0.8;
  }
`;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? -200 : 200,
      opacity: 0,
    };
  },
  center: {
    opacity: 1,
    x: 0,
    transition: {
      damping: 25,
    },
  },
  exit: () => {
    return {
      opacity: 0,
    };
  },
};

export default function ProjectCarousel({
  direction,
  horizontalDragValue,
  title,
  subtitle,
  image,
  mockupScale,
  mockupAnimation,
  footerAnimation,
  color,
  slug,
  hasNavigated,
}: {
  direction: number;
  horizontalDragValue: MotionValue<number>;
  title: string;
  subtitle: string;
  image: FluidObject | FluidObject[];
  mockupScale: MotionValue<number>;
  mockupAnimation: AnimationControls;
  footerAnimation: AnimationControls;
  color: string;
  slug: string;
}) {
  useEffect(() => {
    horizontalDragValue.set(0, false);
    horizontalDragValue.set(0);
  }, [horizontalDragValue]);

  const onClick = () => {
    if (hasNavigated.current) {
      return;
    }
    hasNavigated.current = true;
    navigate(`projects/${slug}`);
  };

  const opacity = useTransform(horizontalDragValue, [-250, 0, 250], [0, 1, 0]);

  return (
    <Container
      initial="enter"
      animate="center"
      exit="exit"
      x={horizontalDragValue}
      opacity={opacity}
    >
      <Mockup variants={variants} custom={direction} scale={mockupScale} animate={mockupAnimation}>
        <Img
          fluid={image}
          style={{ maxHeight: '100%', pointerEvents: 'none' }}
          imgStyle={{ objectFit: 'contain' }}
          draggable={false}
        />
      </Mockup>
      <Footer variants={variants} custom={direction} animate={footerAnimation}>
        <ProjectTitle as="h2">{title}</ProjectTitle>
        <ProjectSubtitle as="h4">{subtitle}</ProjectSubtitle>
        <ProjectLinkContainer>
          <Typography>
            <IconButton
              ariaLabel="View project"
              iconComponent={IoIosArrowRoundForward}
              onClick={onClick}
              iconColor={color}
              renderLabel={() => <ProjectLink>View</ProjectLink>}
              animateDirOnClick={15}
            />
          </Typography>
        </ProjectLinkContainer>
      </Footer>
    </Container>
  );
}
