import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { motion, useAnimation, useSpring, AnimatePresence, AnimationControls } from 'framer-motion';
import { useGesture } from 'react-use-gesture';
import { wrap } from '@popmotion/popcorn';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import VisibilitySensor from 'react-visibility-sensor';

import { IconButton } from 'src/components';
import Project from './Project';
import styled, { mq } from 'src/components/styled';
import { usePreventScroll } from 'src/utils';
import { CursorContext } from 'src/layouts/RootLayout';

// const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

// const thumbnailVariants = {
//   initial: { scale: 0.9, opacity: 0 },
//   enter: { scale: 1, opacity: 1, transition },
//   exit: {
//     scale: 0.5,
//     opacity: 0,
//     transition: { duration: 1.5, ...transition },
//   },
// };

const swipeConfidenceThreshold = 80;
const swipePower = (offset: number, velocity: number) => {
  return offset * velocity;
};

const Container = styled(motion.div)`
  position: relative;
`;

// const CursorHoverBg = styled(motion.div)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   ${mq({
//     height: ['275px', '390px', '520px'],
//   })}
//   background-color: #fff;
//   pointer-events: none;
//   z-index: 2;
// `;

const DragHandler = styled(motion.div)`
  background-color: transparent;
`;

const ProjectBackground = styled(motion.div)`
  width: 100%;
  ${mq({
    height: ['275px', '390px', '520px'],
  })}
  background-image: linear-gradient(
    30deg,
    hsl(0,0%,45%) 0%,
    hsla(0,0%,100%,0) 100%
  );
  background-blend-mode: luminosity;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* border-radius: 16px; */
`;

const PlaceholderDiv = styled.div`
  width: 100%;
  ${mq({
    height: ['275px', '390px', '520px'],
  })}
  position: relative;
  z-index: 1;
`;

const HoverHandler = styled(motion.div)``;

const Footer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  margin-right: -20px;
  margin-left: -20px;
`;

export default function ProjectCarousel({
  homeTopBackgroundAnimation,
  ...rest
}: {
  homeTopBackgroundAnimation: AnimationControls;
}) {
  const data = useStaticQuery(
    graphql`
      query {
        allProjectsJson {
          edges {
            node {
              id
              title
              slug
              platform
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
              color
              backgroundColor
              shadowColor
              highlightColor
            }
          }
        }
      }
    `,
  );
  const projects = data.allProjectsJson.edges;

  const [[page, direction], setPage] = useState([0, 0]);
  const projectIndex = wrap(0, projects.length, page);

  const hasNavigated = useRef(false);

  const preventScrollRef = useRef(false);
  usePreventScroll(preventScrollRef);

  const backgroundAnimation = useAnimation();
  const mockupAnimation = useAnimation();
  const footerAnimation = useAnimation();
  const horizontalDragValue = useSpring(0, { stiffness: 400, damping: 30 });
  const backgroundScale = useSpring(1, { stiffness: 200, damping: 25 });
  const mockupScale = useSpring(1, { stiffness: 200, damping: 25 });

  // CURSOR HOVER ANIMATIONS START

  const bgRef = useRef();
  const isHover = useRef(false);
  const { x, y, animation } = useContext(CursorContext);
  const bgX = useSpring(0, { stiffness: 300, damping: 30, mass: 0.8 });
  const bgY = useSpring(0, { stiffness: 300, damping: 30, mass: 0.8 });
  useEffect(() => {
    x.onChange((newX) => {
      if (isHover.current && bgRef.current) {
        bgX.set(
          (newX -
            (bgRef.current.getBoundingClientRect().left +
              bgRef.current.getBoundingClientRect().right) /
              2 -
            18 / 2) *
            0.075,
        );
      }
    });
    y.onChange((newY) => {
      if (isHover.current && bgRef.current) {
        bgY.set(
          (newY -
            (bgRef.current.getBoundingClientRect().top +
              bgRef.current.getBoundingClientRect().bottom) /
              2) *
            0.1,
        );
      }
    });
  }, [x, bgX, y, bgY]);

  const handleHoverStart = () => {
    isHover.current = true;
    animation.start({ opacity: 0 }, { duration: 0.1 });
    backgroundAnimation.start(
      { backgroundColor: projects[projectIndex].node.highlightColor, borderRadius: 16 },
      { duration: 0.2 },
    );
  };

  const handleHoverEnd = async () => {
    isHover.current = false;
    animation.start({ opacity: 1 }, { duration: 0.1 });
    backgroundAnimation.start(
      { backgroundColor: projects[projectIndex].node.color, borderRadius: 0 },
      { duration: 0.2 },
    );
    bgY.set(0);
    bgX.set(0);
  };

  // CURSOR HOVER ANIMATIONS END

  useEffect(() => {
    backgroundAnimation.start({
      backgroundColor: projects[projectIndex].node.color,
      boxShadow: `0px 0px 40px ${projects[projectIndex].node.shadowColor}`,
    });
    homeTopBackgroundAnimation.start({
      backgroundColor: projects[projectIndex].node.backgroundColor,
    });
  }, [projects, projectIndex, backgroundAnimation, homeTopBackgroundAnimation]);

  useEffect(() => {
    mockupAnimation.start({ scale: 0 });
    footerAnimation.start({ opacity: 0 });
  }, []);

  const paginate = useCallback(
    (newDirection: number) => {
      setPage([page + newDirection, newDirection]);
    },
    [page],
  );
  const goPrev = useCallback(() => paginate(-1), [paginate]);
  const goNext = useCallback(() => paginate(1), [paginate]);

  const isClickingArrow = useRef(false);

  const bind = useGesture({
    onDrag: ({ movement: [mx], down, tap }) => {
      if (tap && isHover.current && !hasNavigated.current) {
        hasNavigated.current = true;
        navigate(`projects/${projects[projectIndex].node.slug}`);
      }
      horizontalDragValue.set(mx);
      preventScrollRef.current = down && Math.abs(mx) > 2;
    },
    onDragEnd: ({ movement: [mx], velocity }) => {
      const swipe = swipePower(mx, velocity);
      if (mx < -250 || swipe < -swipeConfidenceThreshold) {
        goNext();
      } else if (mx > 250 || swipe > swipeConfidenceThreshold) {
        goPrev();
      } else {
        horizontalDragValue.set(0);
      }
    },
  });

  return (
    <VisibilitySensor
      onChange={async (v) => {
        if (v) {
          await backgroundAnimation.start({
            opacity: 1,
            transition: {
              damping: 15,
            },
          });
          mockupAnimation.start({
            transition: {
              type: 'spring',
              stiffness: 400,
              damping: 15,
            },
            scale: 1,
            originX: 'center',
            originY: 'center',
          });
          footerAnimation.start({
            opacity: 1,
            transition: {
              damping: 15,
            },
          });
        }
      }}
      delayedCall
      partialVisibility
      minTopValue={300}
    >
      <Container {...rest}>
        <ProjectBackground
          initial={{
            backgroundColor: projects[0].node.color,
            opacity: 0.001,
          }}
          animate={backgroundAnimation}
          style={{
            x: bgX,
            y: bgY,
            originY: 0.5,
            scale: backgroundScale,
          }}
        />
        <DragHandler {...bind()}>
          <HoverHandler
            onMouseDown={() => {
              backgroundScale.set(0.9);
              mockupScale.set(1.05);
            }}
            onMouseUp={() => {
              backgroundScale.set(1);
              mockupScale.set(1);
            }}
            onHoverStart={handleHoverStart}
            onHoverEnd={handleHoverEnd}
          >
            <AnimatePresence custom={direction} exitBeforeEnter>
              <Project
                key={page}
                horizontalDragValue={horizontalDragValue}
                title={projects[projectIndex].node.title}
                subtitle={projects[projectIndex].node.platform}
                image={projects[projectIndex].node.image.childImageSharp.fluid}
                mockupScale={mockupScale}
                direction={direction}
                mockupAnimation={mockupAnimation}
                footerAnimation={footerAnimation}
                color={projects[projectIndex].node.color}
                slug={projects[projectIndex].node.slug}
                hasNavigated={hasNavigated}
              />
            </AnimatePresence>
            <PlaceholderDiv ref={bgRef} />
          </HoverHandler>

          <Footer style={{ position: 'relative', zIndex: 0 }}>
            <IconButton
              ariaLabel="Previous slide"
              iconComponent={IoIosArrowBack}
              onClick={goPrev}
              onMouseDown={() => {
                isClickingArrow.current = true;
              }}
              onMouseUp={() => {
                isClickingArrow.current = false;
              }}
            />
            <IconButton ariaLabel="Next slide" iconComponent={IoIosArrowForward} onClick={goNext} />
          </Footer>
        </DragHandler>
      </Container>
    </VisibilitySensor>
  );
}
