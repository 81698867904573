import React, { useEffect, useContext, useRef } from 'react';
import { motion, useSpring, useAnimation } from 'framer-motion';

import styled from 'src/components/styled';
import { CursorContext } from 'src/layouts/RootLayout';

const Container = styled.span`
  position: relative;
  display: inline-block;
`;

const Button = styled.button`
  border: none;
`;

const ButtonHoverHandler = styled(motion.span)`
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 2;
  display: inline-block;
  color: ${(p) => p.iconColor || 'rgb(47, 47, 47)'};
`;
const ButtonHoverBackground = styled(motion.span)`
  background-color: rgba(200, 200, 200, 0.9);
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 8px;
  opacity: 0;
  display: inline-block;
`;

const IconMotion = styled(motion.div)`
  display: inline-block;
`;

export default function IconButton({
  iconComponent,
  onClick,
  ariaLabel,
  iconColor,
  renderLabel,
  animateDirOnClick,
  onMouseDown,
  onMouseUp,
}) {
  const buttonRef = useRef();
  const isHover = useRef(false);
  const { x, y, animation } = useContext(CursorContext);

  const iconScale = useSpring(1, { stiffness: 100, damping: 10 });
  const iconAnimation = useAnimation();

  const bgX = useSpring(0, { stiffness: 300, damping: 30, mass: 0.8 });
  const bgY = useSpring(0, { stiffness: 300, damping: 30, mass: 0.8 });
  const bgAnimation = useAnimation();

  const hoverHandlerAnimation = useAnimation();

  useEffect(() => {
    x.onChange((newX) => {
      if (isHover.current && buttonRef.current) {
        bgX.set(
          (newX -
            (buttonRef.current.getBoundingClientRect().left +
              buttonRef.current.getBoundingClientRect().right) /
              2 -
            18 / 2) *
            0.2,
        );
      }
    });
    y.onChange((newY) => {
      if (isHover.current && buttonRef.current) {
        bgY.set((newY - buttonRef.current.getBoundingClientRect().top) * 0.2);
      }
    });
  }, [x, bgX, y, bgY]);

  const handleHoverEnd = async () => {
    isHover.current = false;
    iconScale.set(1);
    animation.start({ opacity: 1 }, { duration: 0.1 });
    if (iconColor) hoverHandlerAnimation.start({ color: iconColor });
    await bgAnimation.start({ opacity: 0, scale: 0.8 }, { duration: 0.2 });
    bgY.set(0);
    bgX.set(0);
  };

  return (
    <Container>
      <ButtonHoverHandler
        onHoverStart={() => {
          isHover.current = true;
          iconScale.set(1.1);
          bgAnimation.start({ opacity: 1, scale: 1 }, { duration: 0.4 });
          animation.start({ opacity: 0 }, { duration: 0.1 });
          if (iconColor) hoverHandlerAnimation.start({ color: 'rgb(47,47,47)' });
        }}
        onHoverEnd={handleHoverEnd}
        onMouseDown={() => {
          onMouseDown && onMouseDown();
          bgAnimation.start({ scale: 0.85 });
        }}
        onMouseUp={() => {
          onMouseUp && onMouseUp();
          bgAnimation.start({ scale: 1 });
        }}
        iconColor={iconColor}
        ref={buttonRef}
        animate={hoverHandlerAnimation}
      >
        <Button
          onClick={() => {
            if (animateDirOnClick) {
              iconAnimation.start({ x: animateDirOnClick });
            }
            onClick();
          }}
          aria-label={ariaLabel}
        >
          {!!renderLabel && renderLabel()}
          <IconMotion style={{ scale: iconScale }} animate={iconAnimation}>
            {React.createElement(iconComponent, {
              size: 48,
            })}
          </IconMotion>
        </Button>
      </ButtonHoverHandler>
      <ButtonHoverBackground
        style={{
          x: bgX,
          y: bgY,
          scale: 0.85,
          originX: 0.5,
          originY: 0.5,
        }}
        animate={bgAnimation}
      />
    </Container>
  );
}
