import React, { CSSProperties } from 'react';
import styledMap from 'styled-map';
import theme from 'src/theme';

import styled from 'src/components/styled';

const fontFamily = styledMap('as', {
  h1: theme.fonts.heading,
  h2: theme.fonts.heading,
  h3: theme.fonts.heading,
  h4: theme.fonts.heading,
  h5: theme.fonts.heading,
  p: theme.fonts.body,
});

const fontSize = styledMap('as', {
  h1: theme.fontSizes[12],
  h2: theme.fontSizes[7],
  h3: theme.fontSizes[5],
  h4: theme.fontSizes[2],
  h5: theme.fontSizes[1],
  p: theme.fontSizes[2],
});

const fontWeights = styledMap('as', {
  h1: theme.fontWeights.heading,
  h2: theme.fontWeights.heading,
  h3: theme.fontWeights.heading,
  h4: theme.fontWeights.heading,
  h5: theme.fontWeights.heading,
  p: theme.fontWeights.body,
});

const lineHeights = styledMap('as', {
  h1: theme.lineHeights.heading,
  h2: theme.lineHeights.heading,
  h3: theme.lineHeights.heading,
  h4: theme.lineHeights.heading,
  h5: theme.lineHeights.heading,
  p: theme.lineHeights.body,
});

export const StyledText = styled.p`
  font-family: ${fontFamily};
  font-size: ${fontSize}px;
  font-weight: ${fontWeights};
  line-height: ${lineHeights};
  margin-top: 0;
`;

export default function Typography({
  children,
  as = 'p',
  className,
  style,
}: // ...rest
{
  children: string | number | JSX.Element;
  as?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  className?: string;
  style?: CSSProperties;
}) {
  const Text = StyledText.withComponent(as);
  return (
    <Text className={className} style={style} as={as}>
      {children}
    </Text>
  );
}
